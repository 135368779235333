export const cameraDatabase = {
  areaData: [
    // 面阵相机系列
    //标准相机
    // { CameraType:'标准相机', productModel: 'BOQ-AG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    // { CameraType:'标准相机', productModel: 'BOQ-AG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG230S-GC', sensorModel: 'IMX392', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG230S-GM', sensorModel: 'IMX392', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG250G-GC', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG250G-GM', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG500S-GC', sensorModel: 'IMX264', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG500S-GM', sensorModel: 'IMX264', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG1600S-GC', sensorModel: 'IMX542', resolution: '5328*3040', maxFrameRate: '52fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG1600S-GM', sensorModel: 'IMX542', resolution: '5328*3040', maxFrameRate: '52fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    // { CameraType:'标准相机', productModel: 'BOQ-AG510S-GC', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    // { CameraType:'标准相机', productModel: 'BOQ-AG510S-GM', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },

    //短款标准相机
    { CameraType:'标准相机', productModel: 'BOQ-AG040S-CC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG040S-CM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG250G-CC', sensorModel: 'GMAX4002', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG250G-CM', sensorModel: 'GMAX4002', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG510S-CC', sensorModel: 'IMX392', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'标准相机', productModel: 'BOQ-AG510S-CM', sensorModel: 'IMX392', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },
    // 智能相机
    { CameraType:'智能相机', productModel: 'BOQ-EAG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '已上线' },
    { CameraType:'智能相机', productModel: 'BOQ-EAG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '已上线' },

    // AI相机
    // { CameraType:'AI相机', productModel: 'BOQ-AG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
    // { CameraType:'AI相机', productModel: 'BOQ-AG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
    // { CameraType:'AI相机', productModel: 'BOQ-AG250G-GC', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
    // { CameraType:'AI相机', productModel: 'BOQ-AG250G-GM', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
    { CameraType:'AI相机', productModel: 'BOQ-AG510S-GC', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
    { CameraType:'AI相机', productModel: 'BOQ-AG510S-GM', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
  ],
  lineData: [
    // 线扫相机系列
    { CameraType:'标准相机', productModel: 'BOQ-L4KG-CGM', sensorModel: 'GL7004', resolution: '4096*1/2/3/4', maxFrameRate: '200KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    { CameraType:'标准相机', productModel: 'BOQ-L8KG-CGM', sensorModel: 'GL7008', resolution: '8192*1/2/3/4', maxFrameRate: '200K/100K/66K/50KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    { CameraType:'标准相机', productModel: 'BOQ-L16KG-CGM', sensorModel: 'GL3516', resolution: '16384*1/2', maxFrameRate: '120K/60KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    // 功能相机
      // null
    // AI相机
    { CameraType:'AI相机', productModel: 'BOQ-AL4KG-CGM', sensorModel: 'GL7004', resolution: '4096*1/2/3/4', maxFrameRate: '200KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    { CameraType:'AI相机', productModel: 'BOQ-AL8KG-CGM', sensorModel: 'GL7008', resolution: '8192*1/2/3/4', maxFrameRate: '200K/100K/66K/50KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    { CameraType:'AI相机', productModel: 'BOQ-AL16KG-CGM', sensorModel: 'GL3516', resolution: '16384*1/2', maxFrameRate: '120K/60KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
    
  ],
  // 添加产品卡片数据
  productCards: [
    {
      title: '标准相机',
      description: '型号众多，想你所需，满足各种场景应用',
      image: require('@/media/face.jpg')
    },
    {
      title: '智能相机',
      description: '简便功能定制，性价比出众',
      image: require('@/media/mini.jpg')
    },
    {
      title: 'AI相机',
      description: 'AI加持，量身定制',
      image: require('@/media/4K.jpg')
    },
    {
      title: '更多产品',
      description: '更多产品，任君挑选',
      image: require('@/media/more.jpg'),
      route: '/MoreProduct'
    }
  ],

  // 相机类型列表
  cameraTypes: ['AI相机', '基础相机', '智能相机'],

  // 产品标题映射
  productTitles: {
    '标准相机': {
      title: '标准相机产品列表',
      titleEn: 'Standard Camera Series'
    },
    '智能相机': {
      title: '智能相机产品列表',
      titleEn: 'Function Camera Series'
    },
    'AI相机': {
      title: 'AI相机产品列表',
      titleEn: 'AI Camera Series'
    },
    '更多产品': {
      title: '更多产品列表',
      titleEn: 'More Products'
    },
    'default': {
      title: '所有产品列表',
      titleEn: 'All Products'
    }
  }
}
