<template>
  <div>
    <el-container>
      <el-main>

        <!-- 图片介绍模块 -->
        <div class="background-image2">
          <div class="left-side" :xs="24" :sm="12" :md="12">
            <img src="../media/cameraProduct.png" alt="Simple Image" loading="lazy" class="responsive-image">
          </div>
          <div class="right-side" :xs="24" :sm="12" :md="12">
            <p class="feature"><strong>功能可选可定制，性能侧重可配置</strong></p>
            <p class="feature2"><strong>支持自定义IO、ROI、Modbus等功能，适应温度范围广、功耗低，性能稳定。</strong></p>
            <table class="original-table">
              <tr>
                <td>外触发</td><td>增益</td><td>曝光</td>
              </tr>
              <tr>
                <td>gamma</td><td>LUT</td><td>Binning</td>
              </tr>
              <tr>
                <td>下采样</td><td>ROI</td><td>边缘检测</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 产品概述模块 -->
        <el-row class="product-section">
          <h2 class="parts-title" style="--after-width: 140px;">产品细节</h2>
          <p class="subtitle">Product Details</p>
          <div class="product-description">
            <p>博峤工业相机系列涵盖AG、AR、LS、AAG、AAR、ALS以及EAG、EAR等多个品类，</p>
            <p>分辨率可选范围广泛，从30万至2000万可选，全面适配GigE、10GigE、USB3.0以及Camera Link等全系列接口。</p>
            <p>相机搭载高品质的成像芯片，优选工业级器件，以及博峤在工业视觉领域的算法造诣，成就了博峤相机的极致高速，稳定，精准。</p>
          </div>
        </el-row>

        <!-- 产品介绍大块 -->
        <el-row :gutter="20" class="product-introduction">
          <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in productCards" :key="index">
            <!-- 点击卡片底色 -->
            <el-card 
              shadow="hover" 
              class="product-card"
              :class="{ selected: selectedCardIndex === index }"
              @click="handleCardClick(item, index)"
              style="cursor: pointer;"
            >
              <div class="card-content">
                <div class="image-container">
                  <img 
                    v-if="item.image" 
                    :src="item.image" 
                    :alt="item.title" 
                    class="product-image"
                  />
                  <div v-else class="no-image">暂无图片</div>
                </div>
                <div class="text-container">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.description }}</p>
                  <!-- 添加选中/未选中状态指示 -->
                  <div v-if="selectedCardIndex === index" class="selection-indicator">
                    <el-icon class="filter-active-icon"><Check /></el-icon>
                    <span>已筛选</span>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>



        <!-- 产品参数下载模块 -->
        <div class="product-switch-section">
          <h2 class="parts-title" style="--after-width: 220px;">{{ currentProductTitle }}</h2>
          <p class="subtitle">{{ currentProductTitleEn }}</p>
          
          <div class="content-wrapper">
            <!-- 切换按钮 -->
            <div class="switch-buttons">
              <el-button 
                :type="!filters.productType ? 'primary' : 'default'"
                @click="switchProduct('all')"
              >
                全部相机
              </el-button>
              <el-button 
                :type="filters.productType === 'area' ? 'primary' : 'default'"
                @click="switchProduct('area')"
              >
                面阵相机
              </el-button>
              <el-button 
                :type="filters.productType === 'line' ? 'primary' : 'default'"
                @click="switchProduct('line')"
              >
                线扫相机
              </el-button>
            </div>

            <!-- 筛选器 -->
            <div class="filters-container">
              <el-row :gutter="20">
                <!-- 相机类型指示和重置按钮 -->
                <el-col :xs="24" :sm="24" :md="24" :lg="24" v-if="filters.cameraType || filters.productType" class="filter-type-indicator">
                  <div class="filter-type-content">
                    <span>
                      当前筛选：
                      <template v-if="filters.productType">
                        {{ filters.productType === 'area' ? '面阵相机' : '线扫相机' }}
                      </template>
                      <template v-if="filters.cameraType">
                        {{ filters.cameraType }}
                      </template>
                    </span>
                    <div>
                      <el-button v-if="filters.cameraType" type="primary" size="small" @click="clearCameraTypeFilter" style="margin-right: 10px;">
                        取消相机类型筛选
                      </el-button>
                      <el-button v-if="filters.productType" type="primary" size="small" @click="switchProduct('all')">
                        显示全部相机
                      </el-button>
                    </div>
                  </div>
                </el-col>
                
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.dataInterface" placeholder="接口类型" clearable>
                    <el-option label="GigE" value="GigE"></el-option>
                    <el-option label="CameraLink/LAN" value="CameraLink/LAN"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.colorType" placeholder="色彩" clearable>
                    <el-option label="黑白" value="黑白"></el-option>
                    <el-option label="彩色" value="彩色"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.productionStatus" placeholder="产品状态" clearable>
                    <el-option label="已上线" value="已上线"></el-option>
                    <el-option label="计划上线" value="计划上线"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.resolution" placeholder="分辨率" clearable>
                    <el-option label="100万像素以下" value="low"></el-option>
                    <el-option label="100-500万像素" value="medium"></el-option>
                    <el-option label="500万以上" value="high"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-button type="info" @click="resetFilters">重置筛选</el-button>
                </el-col>
              </el-row>
            </div>

            <!-- 表格容器 -->
            <div class="table-container">
              <el-table 
                :data="filteredData" 
                style="width: 100%" 
                :row-class-name="tableRowClassName"
              >
                <el-table-column prop="productModel" label="产品型号" min-width="180"></el-table-column>
                <el-table-column prop="sensorModel" label="传感器型号" min-width="120"></el-table-column>
                <el-table-column label="相机类型" min-width="120">
                  <template v-slot="scope">
                    {{ getCameraTypeLabel(scope.row) }}
                  </template>
                </el-table-column>
                <el-table-column prop="resolution" label="分辨率" min-width="150"></el-table-column>
                <el-table-column prop="maxFrameRate" label="最大帧率/行频" min-width="120"></el-table-column>
                <el-table-column prop="dataInterface" label="接口" min-width="120"></el-table-column>
                <el-table-column prop="colorType" label="色彩" min-width="120"></el-table-column>
                <el-table-column prop="productionStatus" label="状态" min-width="120"></el-table-column>
                <el-table-column width="100" label="产品资料" fixed="right">
                  <template v-slot="scope">
                    <el-button 
                      type="primary" 
                      @click="goToDownload(scope.row.productModel)"
                    >
                      <el-icon><Download /></el-icon>
                      下载
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>



      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Delete, Download, Edit, Check } from '@element-plus/icons-vue'
import './Product.css'
import { cameraDatabase } from '@/utils/cameraData.js' // 导入数据

export default {
  components: {
    Edit, Delete, Check
  },
  data() {
    return {
      currentProduct: 'area',
      filters: {
        dataInterface: '',
        colorType: '',
        productionStatus: '',
        resolution: '',
        cameraType: '', // 默认为空字符串，表示不筛选相机类型
        productType: '' // 添加一个新的筛选条件
      },
      // 从 cameraDatabase 中获取数据
      areaData: cameraDatabase.areaData,
      lineData: cameraDatabase.lineData,
      productCards: cameraDatabase.productCards,
      cameraTypes: cameraDatabase.cameraTypes,
      selectedCardIndex: null, // 默认不选中任何卡片
      currentProductTitle: '全部产品',
      selectedCameraType: '',
      currentProductTitleEn: 'All Products',
    }
  },
  computed: {
    tableData() {
      // 检查是否有任何筛选条件
      const hasActiveFilters = Object.values(this.filters).some(value => value !== '');
      
      // 如果没有任何筛选条件，则返回所有相机数据
      if (!hasActiveFilters) {
        return [...this.areaData, ...this.lineData];
      }
      
      // 有筛选条件时，根据当前选择返回对应的数据集
      return this.currentProduct === 'area' ? this.areaData : this.lineData;
    },
    filteredData() {
      let data = this.tableData;
      
      // 根据 productType 筛选相机类型（面阵/线扫）
      if (this.filters.productType) {
        if (this.filters.productType === 'area') {
          data = data.filter(item => this.areaData.includes(item));
        } else if (this.filters.productType === 'line') {
          data = data.filter(item => this.lineData.includes(item));
        }
      }
      
      if (this.filters.dataInterface) {
        data = data.filter(item => item.dataInterface === this.filters.dataInterface);
      }
      
      if (this.filters.colorType) {
        data = data.filter(item => {
          const colors = item.colorType.split('/');
          return colors.includes(this.filters.colorType);
        });
      }
      
      if (this.filters.productionStatus) {
        data = data.filter(item => item.productionStatus === this.filters.productionStatus);
      }
      
      if (this.filters.resolution) {
        data = data.filter(item => {
          const pixels = this.calculatePixels(item.resolution);
          switch(this.filters.resolution) {
            case 'low':
              return pixels < 1000000;
            case 'medium':
              return pixels >= 1000000 && pixels < 5000000;
            case 'high':
              return pixels >= 5000000;
            default:
              return true;
          }
        });
      }
      
      // 根据相机类型进行筛选
      if (this.filters.cameraType) {
        data = data.filter(item => item.CameraType === this.filters.cameraType);
      }
      
      return data;
    }
  },
  methods: {
    calculatePixels(resolution) {
      try {
        // 处理特殊格式的分辨率，如 "4096*1/2/3/4"
        const [width, height] = resolution.split('*');
        const widthNum = parseInt(width);
        let heightNum;
        
        if (height.includes('/')) {
          // 如果高度包含分数，取第一个数字
          heightNum = parseInt(height.split('/')[0]);
        } else {
          heightNum = parseInt(height);
        }
        
        return widthNum * heightNum;
      } catch (error) {
        console.error('Resolution calculation error:', error);
        return 0;
      }
    },
    resetFilters() {
      // 保留相机类型和相机种类（面阵/线扫）的筛选条件
      const productType = this.filters.productType;
      const cameraType = this.filters.cameraType;
      
      // 重置所有筛选条件
      this.filters = {
        dataInterface: '',
        colorType: '',
        productionStatus: '',
        resolution: '',
        cameraType: cameraType, // 保留相机类型筛选
        productType: productType // 保留相机种类筛选
      };
    },
    tableRowClassName({ row, rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },



    goToDownload(productModel) {
      // 构建查询参数
      const query = {
        id: productModel, // 直接使用完整的产品型号
        type: this.currentProduct // 添加当前产品类型（area 或 line）
      }
      
      // 导航到下载页面
      this.$router.push({
        path: '/download',
        query
      })
    },
    switchProduct(type) {
      this.currentProduct = type;
      
      // 只更新相机类型切换相关的筛选条件
      if (type === 'area' || type === 'line') {
        // 添加一个隐藏的筛选条件，表示当前是在查看特定类型的相机
        this.filters.productType = type;
      } else {
        // 如果是其他类型（如 'all'），则清除这个筛选条件
        this.filters.productType = '';
      }
      
      // 保持其他筛选条件不变，避免重置用户已选的其他筛选项
      this.resetFilters();
    },
    handleCardClick(item, index) {
      if (item.title === '更多产品') {
        this.$router.push('/MoreProduct');
      } else {
        // 如果点击的是当前已选中的卡片，则取消选择
        if (this.selectedCardIndex === index) {
          this.selectedCardIndex = null;
          this.filters.cameraType = '';
          this.updateProductTitle('all'); // 显示全部产品的标题
        } else {
          this.selectedCardIndex = index;
          this.filters.cameraType = item.title;
          this.updateProductTitle(item.title);
        }
        this.$forceUpdate();
      }
    },

    updateProductTitle(type) {
      // 使用 cameraDatabase 中的标题映射
      if (type === 'all' || !type) {
        // 显示全部产品的标题
        this.currentProductTitle = '全部产品';
        this.currentProductTitleEn = 'All Products';
        return;
      }
      
      const titleData = cameraDatabase.productTitles[type] || cameraDatabase.productTitles.default;
      this.currentProductTitle = titleData.title;
      this.currentProductTitleEn = titleData.titleEn;
    },

    clearCameraTypeFilter() {
      this.filters.cameraType = '';
      this.selectedCardIndex = null; // 清除选中的卡片
      this.updateProductTitle('all');
      this.$forceUpdate(); // 确保视图更新
    },

    getCameraTypeLabel(row) {
      const cameraType = row.CameraType;
      const cameraTypeLabel = cameraDatabase.cameraTypes[cameraType] || cameraType;
      return cameraTypeLabel;
    }
  },
  watch: {
    'filters.cameraType': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.updateProductTitle(newValue);
        }
      }
    }
  }
};

</script>

<style scoped>
:deep(.el-table .even-row) {
  background-color: #dae7fa;
}

:deep(.el-table .odd-row) {
  background-color: #ffffff;
}

.el-table th, .el-table td {
  text-align: center;
}
table, th, td {
  border: 1px solid transparent;
  text-align: center;
  padding: 5px;
  color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
}
td {
  width: 33.33%; /* Distributes the table cells evenly */
}

/* 在小屏幕下隐藏卡片图片 */
@media (max-width: 768px) {
  /* ... 其他已有的样式 ... */

  .image-container {
    display: none; /* 隐藏图片容器 */
  }

  .card-content {
    padding: 10px; /* 调整内容间距 */
  }

  .text-container {
    padding: 5px 0; /* 调整文字容器间距 */
  }

  .product-card {
    max-width: 100%; /* 让卡片占满宽度 */
  }

  .text-container h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .text-container p {
    font-size: 12px;
  }
}
.responsive-image {
  max-width: 100%;    /* 保持响应式特性 */
  max-height: 300px;  /* 限制最大高度 */
  width: auto;        /* 保持图片比例 */
  height: auto;       /* 保持图片比例 */
  margin: auto;
  display: block;
  object-fit: contain; /* 确保图片整显示且不变形 */
}

.product-description {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-description p {
  margin: 10px 0;
  color: #666;
  line-height: 1.6;
  font-size: 16px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .product-description {
    padding: 0 15px;
  }
  
  .product-description p {
    font-size: 14px;
  }
}

/* ... 其他样式 ... */

.product-section {
  text-align: center;
  width: 100%;
}

.product-section .parts-title,
.product-section .subtitle {
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 25px;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}

/* 筛选指示器样式 */
.selection-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 8px;
  color: #409EFF;
  font-size: 12px;
}

.filter-active-icon {
  font-size: 14px;
  color: #409EFF;
}

/* 相机类型指示器样式 */
.filter-type-indicator {
  margin-bottom: 15px;
}

.filter-type-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(64, 158, 255, 0.1);
  padding: 10px 15px;
  border-radius: 4px;
  border-left: 4px solid #409EFF;
}

.filter-type-content span {
  font-weight: bold;
  color: #409EFF;
}

/* ... 其他样式 ... */
</style>
