<template>
  <div class="news-detail-container">
    <!-- 宣传图片 -->
    <div class="promo-image-container">
      <img src="@/assets/media/boq8.png" alt="upper_underground" class="promo-image" />
    </div>

    <el-row class="row-bg" :gutter="30">
      <!-- 主内容区 -->
      <el-col :xs="24" :sm="24" :md="18" :lg="18" class="content-center">
        <div class="main-content">
          <img :src="news.image" alt="News Image" class="news-image" />
          <h1 class="news-title">{{ news.title }}</h1>
          <p class="news-content">{{ news.content }}</p>
        </div>
      </el-col>

      <!-- 侧边栏 -->
      <el-col :xs="24" :sm="24" :md="6" :lg="5" class="sidebar">
        <div class="sidebar-container">
          <h3 class="sidebar-title">相关推荐</h3>
          <div class="custom-list">
            <div
              class="custom-list-item"
              v-for="(item, index) in paginatedList"
              :key="item.id"
              @click="goToNewsDetail(item.id)"
              :class="{ 'active': news.id === item.id }"
            >
              <div class="list-content">
                <span class="item-number">{{ (currentPage - 1) * 5 + index + 1 }}</span>
                <p class="list-item-title">{{ item.title }}</p>
              </div>
            </div>
          </div>

          <el-pagination
            v-if="news_list.length > 5"
            :current-page.sync="currentPage"
            :page-size="5"
            :total="news_list.length"
            layout="prev, pager, next"
            @current-change="handlePageChange"
            class="pagination-container"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: {}, // 用于存储当前新闻详情
      news_list:[
        { id: 1, title: '冲压模具检测' },
        { id: 2, title: '振动盘CCD分选系统' },
        { id: 3, title: '器件有无检测' },
        { id: 4, title: '标准相机' },
      ],
      value: false, // switch 的初始状态
      currentPage: 1, // 当前页码

    };

  },
  computed: {
  // 动态计算分页后的列表
  paginatedList() {
    const start = (this.currentPage - 1) * 5;
    const end = start + 5;
    return this.news_list.slice(start, end);
  },
},
created() {
  const sampleID = parseInt(this.$route.params.id);
  this.loadNewsDetail(sampleID);

  // 根据当前新闻 ID 计算所在的页码
  this.updateCurrentPage(sampleID);

  // 根据 news_list 的数量更新开关状态
  this.value = this.news_list.length >= 5;
},
  methods: {
    loadNewsDetail(id) {
          const newsData = [
  {
    id: 1,
    title: '冲压模具检测',
    image: require('@/assets/media/newimage1.jpg'),
    content: '在工业制造领域，冲压模具检测是确保产品质量的关键环节，而博峤科技的工业相机解决方案为此提供了高效、精准的技术支持。该方案搭载了高性能的Xilinx 7ev芯片，与博峤工业相机型号BoQ-AG230S-GC完美结合，实现了对接线端子的高速、高精度检测。\n\nBoQ-AG230S-GC工业相机以其卓越的图像采集能力，能够捕捉接线端子的每一个细微特征。同时，相机内置的Xilinx 7ev芯片提供了强大的数据处理能力，确保了图像传输与处理的实时性。在此基础上，博峤自研的边缘检测算法进一步提升了检测精度，通过算法对图像中的边缘、轮廓等特征进行精确识别与分析，从而准确判断接线端子的形状、尺寸等关键参数是否符合标准。\n\n此外，为了获得更清晰的图像效果，该方案还配备了博峤自研的灯箱型号BoQ-Light-01。该灯箱提供了稳定、均匀的光照环境，有效消除了光线对图像质量的影响，进一步提升了检测的准确性。\n\n在帧率方面，该方案更是达到了惊人的201fps，这意味着每秒钟可以捕获201帧图像，从而实现了对冲压模具生产线上接线端子的连续、实时监测。这一高帧率特性不仅提高了检测效率，还确保了检测结果的可靠性，为工业生产提供了有力的技术支持。'
  },
  {
    id: 2,
    title: '振动盘CCD分选系统',
    image: require('@/assets/media/newimage2.jpg'),
    content: '在工业自动化领域，针对CCD器件的分选与检测，我们采用了一套高效、智能的解决方案。该方案的核心在于搭载了Xilinx 7ev芯片的工控机，其强大的数据处理能力为4个博峤工业相机型号BoQ-AG040S-GC提供了坚实的支持。每个相机均通过CameraLink接口与工控机相连，实现了高速、稳定的图像传输。\n\n这4个博峤工业相机以并行处理的方式工作，共同捕捉振动盘中CCD器件的图像。在博峤自研检测算法的加持下，系统能够迅速分析图像中的器件特征，包括其形状、尺寸以及朝向等关键信息。特别是针对器件的正确朝向，算法能够进行精确识别，确保分选的准确性。\n\n为了获得更清晰的图像效果，我们还配备了博峤自研的灯箱型号BoQ-Light-01。该灯箱提供了稳定、均匀的光照环境，有效消除了光线干扰，进一步提升了检测的精度与稳定性。\n\n当检测到CCD器件朝向不正确时，系统会立即通过IO口触发吹气装置，使器件发生翻面，从而实现自动校正。这一过程中，4个相机并行处理的能力得到了充分发挥，使得整个检测流程异常流畅，检测速度高达4000帧/秒，极大地提高了生产效率与分选精度。这一方案不仅满足了工业自动化对于高效、智能的需求，更为CCD器件的分选与检测提供了新的思路与技术手段。'
  },
  {
    id: 3,
    title: '器件有无检测',
    image: require('@/media/background3.png'),
    content: '博峤自研的功能相机BoQ-EAG040S-GM，以其卓越的实用性和专业性，在工业自动化领域的有无检测中独树一帜。这款相机在标准工业相机的基础上，创新性地内置了有无检测算法，无需外接工控机，即可独立完成元器件的存在性检测，极大地节省了系统资源与成本。BoQ-EAG040S-GM采用了千兆网口接口，确保了数据传输的高速与稳定，同时，通过行插口即可实现供电与IO控制信号的输出，操作简便且高效。\n\n相机内置的实时查看抓取图像与回放设置功能，使得生产过程中的每一个细节都尽在掌握，为质量监控与问题追溯提供了强有力的支持。在元器件转动盘的检测中，BoQ-EAG040S-GM更是发挥着不可替代的作用，它能够快速准确地判断元器件是否存在，一旦检测到缺失，立即输出控制信号，触发报警或补料机制，从而确保了生产线的连续稳定运行。这一方案不仅体现了博峤在工业自动化领域的深厚技术积累，更为客户提供了高效、可靠的检测解决方案。'
  },
  {
    id: 4,
    title: '标准相机',
    image: require('@/media/background.png'),
    content: '博峤自主研发的标准工业相机系列，以其广泛覆盖的像素范围——从精细的30万像素到超清的2000万像素，充分满足了不同工业应用场景下的多样化需求。这些相机不仅配备了高速稳定的GigE（千兆以太网）和灵活便捷的USB接口，确保了数据传输的高效与兼容性，还完美适配市面上主流的工业相机上位机软件，为用户提供了无缝对接、即插即用的使用体验。\n\n在性能上，博峤标准工业相机凭借卓越的高速捕捉能力和高清成像效果，成为了众多自动化生产线与精密检测任务中的首选。同时，得益于优化的生产流程与成本控制，这些相机在成品价格上也展现出了明显的竞争优势，为用户带来了更高的性价比。\n\n为了确保产品质量的稳定与可靠，博峤与索尼、长光辰芯等国际知名的图像传感器厂商建立了深度的合作关系，从而拥有了稳定且优质的原材料供应渠道。此外，依托上市公司博杰的强大背景，博峤还建立了可靠、专业的生产线与品质把控体系，从源头上保障了每一台出厂相机的卓越品质与耐用性。'
  }
]

      this.news = newsData.find(item => item.id === parseInt(id));
    },

    handlePageChange(page) {
    this.currentPage = page;
  },
  goToNewsDetail(id) {
    this.$router.push({ path: `/Sample/${id}` });

    // 跳转后更新当前页码
    this.updateCurrentPage(id);
  },
  updateCurrentPage(id) {
    // 根据新闻 ID 动态计算页码
    const index = this.news_list.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.currentPage = Math.floor(index / 5) + 1;
    }
  },
  }
};
</script>
<style scoped>
.active {
  background-color: #e3f2fd; /* 高亮背景色 */
  font-weight: bold; /* 加粗字体 */
  border-right: 10px solid #6e91b4; /* 左侧边框 */
}

.news-detail-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/media/backgorund3.png'); */
  background-size: cover; /* 背景图覆盖整个容器 */
  background-position: center; /* 背景图居中 */
}

/* 宣传图片样式 */
.promo-image-container {
  margin-bottom: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.promo-image {
  width: 100%;
  height: auto; /* 改为自动高度 */
  max-height: 600px; /* 增加最大高度，给更多空间显示 */
  object-fit: contain; /* 改为contain，确保图片完整显示 */
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  display: block;
}

/* 主内容区样式 */
.main-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
}

.content-center {
  display: flex;
  flex-direction: column;
}

.news-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.news-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 25px;
  text-align: left;
}

.news-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #34495e;
  text-align: justify;
  padding: 20px;
  background-color: #f8f9fa;
  border-left: 6px solid #409eff;
  border-radius: 4px;
  margin: 20px 0;
}

/* 侧边栏样式优化 */
.sidebar-container {
  position: sticky;
  top: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.sidebar-title {
  font-size: 1.3rem;
  color: #2c3e50;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #409eff;
  text-align: center;
  position: relative;
}

.sidebar-title::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: #409eff;
}

/* 列表项样式优化 */
.custom-list-item {
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
  overflow: hidden;
}

.custom-list-item:hover {
  transform: translateX(5px);
  background-color: #e3f2fd;
}

.item-number {
  background: linear-gradient(135deg, #409eff, #64b5f6);
  box-shadow: 0 2px 4px rgba(64, 158, 255, 0.2);
}

/* 响应式设计增强 */
@media (max-width: 1400px) {
  .news-detail-container {
    padding: 15px;
  }
}

@media (max-width: 1200px) {
  .news-title {
    font-size: 1.8rem;
  }

  .news-content {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .main-content {
    padding: 20px;
  }

  .sidebar-container {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .news-detail-container {
    padding: 10px;
  }

  .news-title {
    font-size: 1.5rem;
  }

  .main-content {
    padding: 15px;
  }

  .sidebar-container {
    position: static;
    margin-top: 20px;
  }

  .custom-list-item {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .news-title {
    font-size: 1.3rem;
  }

  .news-content {
    padding: 15px;
    font-size: 0.95rem;
  }

  .sidebar-title {
    font-size: 1.1rem;
  }
}

/* 动画效果 */
.custom-list-item {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
