<template>
  <div class="tech-support-container">
    <!-- 顶部卡片导航 -->
    <div class="category-cards">
      <el-card 
        v-for="(category, index) in categories" 
        :key="index"
        class="category-card"
        :class="{ active: currentCategory === category.key }"
        @click="switchCategory(category.key)"
      >
        <div class="card-content">
          <el-icon class="category-icon" :size="32">
            <component :is="category.icon" />
          </el-icon>
          <h3>{{ category.title }}</h3>
          <p>{{ category.description }}</p>
        </div>
      </el-card>
    </div>

    <!-- 内容显示区域 -->
    <el-card class="content-card">
      <template #header>
        <div class="content-header">
          <h2>{{ getCurrentCategory.title }}</h2>
          <el-input
            v-model="searchText"
            placeholder="搜索相关内容"
            prefix-icon="Search"
            clearable
            class="search-input"
          />
        </div>
      </template>

      <!-- 基础知识内容 -->
      <div v-if="currentCategory === 'basic'" class="content-section">
        <el-collapse>
          <el-collapse-item 
            v-for="(item, index) in filteredBasicKnowledge" 
            :key="index"
          >
            <template #title>
              <div class="collapse-title">
                <span>{{ item.title }}</span>
                <el-button 
                  type="primary" 
                  link
                  class="download-btn"
                  @click.stop="downloadPDF(item)"
                >
                  <el-icon><Download /></el-icon>
                  PDF详情下载
                </el-button>
              </div>
            </template>
            <div class="knowledge-content" v-html="item.content"></div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- 应用技术内容 -->
      <div v-else-if="currentCategory === 'application'" class="content-section">
        <el-collapse>
          <el-collapse-item 
            v-for="(item, index) in filteredApplicationTech" 
            :key="index"
          >
            <template #title>
              <div class="collapse-title">
                <span>{{ item.title }}</span>
                <el-button 
                  type="primary" 
                  link
                  class="download-btn"
                  @click.stop="downloadPDF(item)"
                >
                  <el-icon><Download /></el-icon>
                  PDF详情下载
                </el-button>
              </div>
            </template>
            <div class="knowledge-content">
              <p>{{ item.description }}</p>
              <div v-if="item.detail" class="detail-content" v-html="item.detail"></div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- 问题解决内容 -->
      <div v-else-if="currentCategory === 'solution'" class="content-section">
        <el-table :data="filteredSolutions" style="width: 100%">
          <el-table-column prop="problem" label="常见问题" width="300" />
          <el-table-column prop="solution" label="解决方案" />
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope">
              <el-button 
                link 
                type="primary" 
                @click="showSolutionDetail(scope.row)"
              >
                详细步骤
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 详情对话框 -->
    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="60%"
      destroy-on-close
    >
      <div v-html="dialogContent"></div>
    </el-dialog>
  </div>
</template>

<script>
import { Download } from '@element-plus/icons-vue'

export default {
  name: 'TechSupport',
  components: {
    Download
  },
  data() {
    return {
      currentCategory: 'basic',
      searchText: '',
      dialogVisible: false,
      dialogTitle: '',
      dialogContent: '',
      categories: [
        {
          key: 'basic',
          title: '基础知识',
          description: '相机基本原理与概念',
          icon: 'Reading'
        },
        {
          key: 'application',
          title: '应用技术',
          description: '实际应用案例与技术',
          icon: 'Connection'
        },
        {
          key: 'solution',
          title: '问题解决',
          description: '常见问题与解决方案',
          icon: 'Service'
        }
      ],
      basicKnowledge: [
        {
          title: '什么是机器视觉？',
          content: 
           `
            <h4>应用场景</h4>
            <p>机器视觉主要应用于自动化检测、质量控制和机器人导航等领域，如生产线上产品的缺陷检测、自动装配和识别系统。</p>

            <h4>技术方案</h4>
            <ul>
              <li>使用高精度工业相机捕捉图像</li>
              <li>集成LED或激光照明系统增强图像效果</li>
              <li>采用图像处理算法进行模式识别和对象定位</li>
            </ul>

            <h4>实施效果</h4>
            <p>在生产线的质量检测中，检测精度达到99%，大大降低了人工误差，提升了生产效率。</p>

           `,
          pdfUrl: '/files/machine-vision-intro.pdf'
        },
        {
          title: '相机的基本原理',
          content:            `
            <h4>应用场景</h4>
            <p>相机的基本原理广泛应用于摄影、摄像、安防监控等场景，帮助捕捉清晰的图像或视频内容。</p>

            <h4>技术方案</h4>
            <ul>
              <li>采用高质量镜头组合进行光学聚焦</li>
              <li>使用CCD或CMOS感光元件进行光信号转换</li>
              <li>机械或电子快门控制曝光时间</li>
            </ul>

            <h4>实施效果</h4>
            <p>相机能够在不同光照条件下获取高质量的图像，清晰捕捉到目标对象，适用于多种光线环境下的拍摄需求。</p>

           `,
          pdfUrl: '/files/camera-params.pdf'
        },

        {
          title: '相机参数详解',
          content:
          `
            <h4>应用场景</h4>
            <p>相机参数的调整应用于摄影、影视制作、机器视觉系统，帮助根据不同环境条件进行优化拍摄。</p>

            <h4>技术方案</h4>
            <ul>
              <li>根据拍摄需求选择适当的分辨率和帧率</li>
              <li>通过调节ISO、光圈和快门速度实现曝光控制</li>
              <li>根据场景选择焦距以确定拍摄视角</li>
            </ul>

            <h4>实施效果</h4>
            <p>通过合理调整参数，图像质量得到显著提升，能适应不同的光照和运动条件，确保精确捕捉到细节。</p>
              
           `,
          pdfUrl: '/files/camera-params.pdf'
        },

        {
          title: '镜头种类详解',
          content:
          `
            <h4>应用场景</h4>
            <p>不同种类的镜头在风景摄影、人像摄影、野生动物拍摄以及显微观察中应用广泛。</p>

            <h4>技术方案</h4>
            <ul>
              <li>使用广角镜头拍摄大范围风景</li>
              <li>长焦镜头用于拍摄远距离物体</li>
              <li>微距镜头用于捕捉微小物体的细节</li>
            </ul>

            <h4>实施效果</h4>
            <p>根据应用场景选择合适的镜头，可以确保不同拍摄需求下的图像质量，保证在特定距离和场景下获得最佳效果。</p>

          `,
          pdfUrl: '/files/camera-params.pdf'
        },

        {
          title: '机器视觉中的相机选择',
          content:
          `
            <h4>应用场景</h4>
            <p>机器视觉中的相机选择在制造业、物流、医疗检测中发挥重要作用，例如检测产品缺陷、物体识别和追踪。</p>

            <h4>技术方案</h4>
            <ul>
              <li>根据检测要求选择高分辨率或高帧率相机</li>
              <li>搭配合适的镜头和光源系统</li>
              <li>结合图像处理软件进行分析</li>
            </ul>

            <h4>实施效果</h4>
            <p>相机的选择优化了系统性能，检测精度和速度显著提升，能够高效处理复杂的检测任务。</p>
              
          `,
          pdfUrl: '/files/camera-params.pdf'
        },

        {
          title: '图像处理与分析',
          content:
          `
            <h4>应用场景</h4>
            <p>图像处理和分析主要应用于自动化识别、物体检测、医疗影像分析等领域。</p>

            <h4>技术方案</h4>
            <ul>
              <li>使用边缘检测算法提取物体轮廓</li>
              <li>采用深度学习算法进行模式识别</li>
              <li>进行颜色分析和3D建模</li>
            </ul>

            <h4>实施效果</h4>
            <p>通过图像处理技术，系统能够快速识别物体特征，并精确分析图像内容，提高了自动化系统的智能化水平。</p>
            
          `,
          pdfUrl: '/files/camera-params.pdf'
        }
        // 更多基础知识条目
      ],
      applicationTech: [
        {
          title: '工业缺陷检测应用',
          description: '使用机器视觉进行产品缺陷检测的案例分析。通过高精度相机采集图像，结合深度学习算法，实现自动化质量检测。',
          detail: `
            <h4>应用场景</h4>
            <p>工业缺陷检测用于在生产线中实时检测产品表面是否存在瑕疵，如电子元件、PCB板、金属制品等。通过相机拍摄产品的表面图像，分析其是否存在划痕、污点、气泡等不合格的缺陷。</p>

            <h4>技术方案</h4>
            <ul>
              <li>采用高分辨率工业相机拍摄产品表面细节，确保能捕捉到微小瑕疵</li>
              <li>使用定制光源方案，如环形灯或背光，避免表面反光和阴影</li>
              <li>通过图像处理算法进行边缘检测、纹理分析和模式匹配，识别产品表面缺陷</li>
              <li>结合深度学习模型，通过训练大量缺陷样本，提高检测的准确性和自适应性</li>
            </ul>

            <h4>实施效果</h4>
            <p>缺陷检测系统能以99.9%的准确率检测到生产中的瑕疵，减少人为检查的误差，并将产品不良率降低30%，显著提高生产效率。</p>

          `,
          pdfUrl: '/files/defect-detection.pdf'
        },
        {
          title: '尺寸测量应用',
          description: '基于机器视觉的高精度尺寸测量解决方案',
          detail: `
            <h4>应用场景</h4>
            <p>尺寸测量应用于对机械零件、电子元件等进行精确的尺寸检测。通过工业相机实时测量产品的长度、宽度、高度等，确保其符合设计要求，避免尺寸超差问题。</p>

            <h4>技术方案</h4>
            <ul>
              <li>使用工业相机配合高精度镜头进行无接触测量，避免测量误差</li>
              <li>利用多角度相机阵列拍摄，生成3D模型，精准计算物体的尺寸</li>
              <li>图像处理算法对物体边缘进行亚像素级检测，以提高测量精度</li>
              <li>通过条形光源或激光辅助光源，确保物体轮廓清晰，以便准确识别边界</li>
            </ul>

            <h4>实施效果</h4>
            <p>尺寸测量系统能够在0.01mm的精度范围内测量物体，并将测量时间减少了50%，提高了质量控制的效率。</p>

            `,
          pdfUrl: '/files/dimension-measurement.pdf'
        }
        ,
        {
          title: '定位应用',
          description: '通过工业相机识别产品的具体位置和方向，确保机器人能精确抓取和安装产品',
          detail: `
            <h4>应用场景</h4>
            <p>自动化装配线中的定位应用，通过工业相机识别产品的具体位置和方向，确保机器人能精确抓取和安装产品。</p>

            <h4>技术方案</h4>
            <ul>
              <li>采用高速相机拍摄产品的实时图像，跟踪产品在流水线上的移动轨迹</li>
              <li>结合图像识别算法，确定物体的位置、方向及姿态</li>
              <li>通过标定和配准技术，确保相机坐标与机器人坐标一致</li>
              <li>将机器视觉与PLC（可编程逻辑控制器）集成，实时控制机器人动作</li>
            </ul>

            <h4>实施效果</h4>
            <p>定位系统精确识别产品位置，误差在0.1mm以内，显著提升了机器人装配的速度和准确度，实现了100%的自动化生产。</p>

            `,
          pdfUrl: '/files/dimension-measurement.pdf'
        }
        ,
        {
          title: '条码与字符识别应用',
          description: '物流分拣、药品包装追踪',
          detail: `
            <h4>应用场景</h4>
            <p>工业相机用于自动识别生产线上产品包装或零件上的条码和字符，常用于物流分拣、药品包装追踪等领域。</p>

            <h4>技术方案</h4>
            <ul>
              <li>使用高帧率相机抓取移动产品的条码和字符图像</li>
              <li>结合OCR（光学字符识别）技术，识别包装上的字母、数字或特殊符号</li>
              <li>采用条码识别算法，快速扫描并解析一维码、二维码信息</li>
              <li>通过图像增强技术，在低对比度或光线不均匀的情况下提高识别率</li>
            </ul>

            <h4>实施效果</h4>
            <p>条码和字符识别系统在高速生产线上能以99%的准确率读取条码和字符，大大减少了人工扫描错误，提高了物流和生产的自动化水平。</p>

            `,
          pdfUrl: '/files/dimension-measurement.pdf'
        },
        {
          title: '包装完整性检测应用',
          description: '确保包装封口是否牢固、标签是否正确贴好',
          detail: `
            <h4>应用场景</h4>
            <p>包装完整性检测常用于食品、药品等行业，确保包装封口是否牢固、标签是否正确贴好、外包装是否有破损等。</p>

            <h4>技术方案</h4>
            <ul>
              <li>通过工业相机拍摄产品包装的多角度图像，确保各个部分都能被检测到</li>
              <li>结合边缘检测算法，识别包装是否有破损或开口</li>
              <li>标签识别系统确保产品标签正确贴附并与产品信息匹配</li>
              <li>使用缺陷检测算法检测包装的皱褶、撕裂等细节</li>
            </ul>

            <h4>实施效果</h4>
            <p>包装检测系统能够以99.8%的准确率检测到包装中的不良品，减少了漏检情况，同时提高了生产线的整体效率。</p>

            `,
          pdfUrl: '/files/dimension-measurement.pdf'
        }
        // 可以添加更多应用技术案例
      ],
      solutions: [
        {
          problem: '相机无法连接',
          solution: '检查连接配置',
          detail: `
            <ul>
              <li>检查相机的电源和连接线是否正常工作</li>
              <li>确认接口（USB、以太网等）是否牢固连接，或尝试更换接口</li>
              <li>检查相机驱动程序是否安装正确，尝试重新安装驱动</li>
              <li>确认设备管理器中是否识别到相机设备，必要时更新固件</li>
              <li>排查网络摄像头的IP地址、子网掩码、网关等网络配置</li>
            </ul>
          `
        },
        {
          problem: '图像质量不佳',
          solution: '调整相机参数和环境',
          detail: `
            <ul>
              <li>检查并调整曝光时间、增益等基本参数</li>
              <li>确保光源充足且均匀，避免过度曝光或欠曝</li>
              <li>调整相机与目标物体的距离和角度</li>
              <li>检查镜头是否清洁，必要时进行清洁维护</li>
              <li>确认镜头参数是否适合当前应用场景</li>
            </ul>
          `
        },
        {
          problem: '帧率不达标',
          solution: '优化采集设置',
          detail: `
            <ul>
              <li>检查相机的帧率设置是否正确</li>
              <li>确认传输带宽是否满足要求</li>
              <li>适当降低分辨率或ROI区域大小</li>
              <li>检查主机性能是否满足要求</li>
              <li>优化图像处理算法，减少不必要的运算</li>
            </ul>
          `
        },
        {
          problem: '触发信号异常',
          solution: '检查触发设置和接线',
          detail: `
            <ul>
              <li>验证触发模式设置是否正确</li>
              <li>检查触发信号线的连接是否可靠</li>
              <li>确认触发信号的电平和时序是否符合要求</li>
              <li>测试触发信号源是否正常工作</li>
              <li>检查软件中的触发参数配置</li>
            </ul>
          `
        },
        {
          problem: '软件崩溃',
          solution: '排查软件环境',
          detail: `
            <ul>
              <li>检查系统环境是否满足软件运行要求</li>
              <li>确认软件版本与相机固件版本是否匹配</li>
              <li>清理系统垃圾文件和临时文件</li>
              <li>检查系统资源占用情况</li>
              <li>必要时重新安装软件或更新至最新版本</li>
            </ul>
          `
        },
        {
          problem: '图像存储失败',
          solution: '检查存储配置',
          detail: `
            <ul>
              <li>确认存储路径是否有效且有写入权限</li>
              <li>检查磁盘空间是否充足</li>
              <li>验证文件命名格式是否正确</li>
              <li>检查存储介质是否正常工作</li>
              <li>确认存储格式设置是否正确</li>
            </ul>
          `
        },
        {
          problem: '相机发热严重',
          solution: '检查使用环境',
          detail: `
            <ul>
              <li>确认环境温度是否在工作范围内</li>
              <li>检查散热条件是否良好</li>
              <li>适当调整相机工作参数</li>
              <li>检查电源供电是否正常</li>
              <li>必要时增加散热措施</li>
            </ul>
          `
        },
        {
          problem: '图像噪点过多',
          solution: '优化成像参数',
          detail: `
            <ul>
              <li>降低相机增益值</li>
              <li>适当延长曝光时间</li>
              <li>改善光照条件</li>
              <li>启用降噪功能</li>
              <li>检查信号传输是否受干扰</li>
            </ul>
          `
        }
      ]
    }
  },
  computed: {
    getCurrentCategory() {
      return this.categories.find(c => c.key === this.currentCategory) || this.categories[0]
    },
    filteredBasicKnowledge() {
      return this.basicKnowledge.filter(item => 
        this.searchText ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) : true
      )
    },
    filteredApplicationTech() {
      return this.applicationTech.filter(item =>
        this.searchText ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) : true
      )
    },
    filteredSolutions() {
      return this.solutions.filter(item =>
        this.searchText ? 
          item.problem.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.solution.toLowerCase().includes(this.searchText.toLowerCase())
        : true
      )
    }
  },
  methods: {
    switchCategory(category) {
      this.currentCategory = category
    },
    showDetail(item) {
      this.dialogTitle = item.title
      this.dialogContent = item.detail
      this.dialogVisible = true
    },
    showSolutionDetail(solution) {
      this.dialogTitle = solution.problem
      this.dialogContent = solution.detail
      this.dialogVisible = true
    },
    downloadPDF(item) {
      if (!item.pdfUrl) {
        this.$message.warning('PDF文档暂未上传，请联系工作人员')
        return
      }

      fetch(item.pdfUrl)
        .then(response => {
          if (!response.ok) throw new Error('File not found')
          return response.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${item.title}.pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$message.error('下载失败，请稍后重试')
        })
    }
  }
}
</script>

<style scoped>
.tech-support-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.category-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.category-card {
  cursor: pointer;
  transition: all 0.3s;
}

.category-card:hover {
  transform: translateY(-5px);
}

.category-card.active {
  border: 2px solid var(--el-color-primary);
}

.card-content {
  text-align: center;
  padding: 20px;
}

.category-icon {
  font-size: 32px;
  color: var(--el-color-primary);
  margin-bottom: 15px;
}

.card-content h3 {
  margin: 10px 0;
  color: var(--el-text-color-primary);
}

.card-content p {
  color: var(--el-text-color-secondary);
  font-size: 14px;
}

.content-card {
  margin-top: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 300px;
}

.content-section {
  min-height: 400px;
}

.knowledge-content {
  padding: 10px;
  line-height: 1.6;
}

/* 响应式布局 */
@media screen and (max-width: 1200px) {
  .category-cards {
    gap: 15px;
  }
  
  .search-input {
    width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .category-cards {
    grid-template-columns: 1fr;
  }
  
  .content-header {
    flex-direction: column;
    gap: 15px;
  }
  
  .search-input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .tech-support-container {
    padding: 10px;
  }
  
  .card-content {
    padding: 15px;
  }
}

/* 添加新的样式 */
.collapse-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.download-btn {
  margin-left: 15px;
  font-size: 14px;
}

.download-btn :deep(.el-icon) {
  margin-right: 4px;
}

/* 确保按钮在移动端也能正常显示 */
@media screen and (max-width: 480px) {
  .collapse-title {
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .download-btn {
    margin-left: 0;
  }
}

.date-text {
  font-size: 13px;
  color: var(--el-text-color-secondary);
  margin-left: 15px;
}

.knowledge-content {
  padding: 15px;
}

.knowledge-content p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.detail-content {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--el-border-color-lighter);
}
</style> 