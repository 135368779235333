<!-- src/views/Subscribe.vue -->
<!-- 订阅功能最终目标要实现点击订阅跳转到支付界面用户付款后返回收款id -->
<template>
  <div class="subscribe-container">
    <h1 class="page-title" style="--after-width: 240px;">咨询服务</h1>
    <p class="subtitle">Consulting Service</p>
    
    <!-- 服务卡片区域 -->
    <div class="service-cards">
      <!-- 24小时工程师协助 -->
      <el-card class="service-card" shadow="hover">
        <div class="card-content">
          <div class="image-container">
            <el-image 
              :src="require('@/assets/media/boq.jpg')" 
              fit="cover"
              class="service-image">
              <template #error>
                <div class="image-placeholder">
                  <el-icon><Picture /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
          <h2 class="service-title">24小时工程师协助</h2>
          <div class="service-description">
            <p>全天候技术支持服务</p>
            <p>专业工程师在线解答</p>
            <p>快速响应技术问题</p>
          </div>
          <el-button type="primary" class="service-button" @click="contactService('24h')">
            立即咨询
          </el-button>
        </div>
      </el-card>

      <!-- 快速模块化部署 -->
      <el-card class="service-card" shadow="hover">
        <div class="card-content">
          <div class="image-container">
            <el-image 
              :src="require('@/assets/media/boq5.jpg')" 
              fit="cover"
              class="service-image">
              <template #error>
                <div class="image-placeholder">
                  <el-icon><Picture /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
          <h2 class="service-title">2小时快速部署</h2>
          <div class="service-description">
            <p>模块化解决方案</p>
            <p>最快2小时完成部署</p>
            <p>专业团队远程指导</p>
          </div>
          <el-button type="primary" class="service-button" @click="contactService('deploy')">
            立即咨询
          </el-button>
        </div>
      </el-card>

      <!-- 线下工程师协助 -->
      <el-card class="service-card" shadow="hover">
        <div class="card-content">
          <div class="image-container">
            <el-image 
              :src="require('@/assets/media/boq8.png')" 
              fit="cover"
              class="service-image">
              <template #error>
                <div class="image-placeholder">
                  <el-icon><Picture /></el-icon>
                </div>
              </template>
            </el-image>
          </div>
          <h2 class="service-title">线下工程师协助</h2>
          <div class="service-description">
            <p>专业工程师现场支持</p>
            <p>一对一技术指导</p>
            <p>解决方案定制服务</p>
          </div>
          <el-button type="primary" class="service-button" @click="contactService('offline')">
            立即咨询
          </el-button>
        </div>
      </el-card>
    </div>
    <h1 class="page-title" style="--after-width: 240px;">暂未开放</h1>
    <p class="subtitle">Comming Soon</p>
  </div>
</template>

<script>
import { Picture } from '@element-plus/icons-vue'

export default {
  components: {
    Picture
  },
  methods: {
    contactService(type) {
      // 根据不同服务类型显示不同的联系信息
      const messages = {
        '24h': '24小时服务热线：15914152976',
        'deploy': '模块化部署咨询：开发中 敬请期待',
        'offline': '线下支持预约：17882351005'
      }
      this.$message({
        message: messages[type],
        type: 'info',
        duration: 5000
      })
    }
  }
}
</script>

<style scoped>
.subscribe-container {
  text-align: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* 标题样式 */
.page-title {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  padding-left: 0px;
}

/* 伪元素 平行四边形 */
.page-title::after {
  content: '';
  display: block;
  width: var(--after-width);
  height: 6px;
  background-color: #409eff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) skewX(-20deg);
  bottom: -1px;
}

/* 副标题样式 */
.subtitle {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 40px;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* 服务卡片区域 */
.service-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 auto;
  max-width: 1200px;
}

.service-card {
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.image-container {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 8px;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-image:hover {
  transform: scale(1.05);
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  color: #909399;
  font-size: 24px;
}

.service-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 15px;
}

.service-description {
  text-align: center;
  color: #606266;
  margin-bottom: 20px;
  min-height: 80px;
}

.service-description p {
  margin: 8px 0;
  font-size: 14px;
}

.service-button {
  width: 120px;
}

/* 响应式布局 */
@media screen and (max-width: 1024px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .service-cards {
    grid-template-columns: 1fr;
  }
  
  .page-title {
    font-size: 24px;
  }
  
  .subtitle {
    font-size: 12px;
  }
  
  .service-card {
    margin-bottom: 20px;
  }
}
</style>