<!-- src/views/productList/MoreProduct.vue -->
<template>
  <div class="more-product-container">
    <el-container>
      <el-main>
        <!-- 顶部图片容器 -->
        <div class="promo-image-container">
          <img src="@/assets/media/boq7.jpg" alt="Banner" class="promo-image">
        </div>

        <!-- 标题区域 -->
        <h1 class="page-title" style="--after-width: 140px;">配件</h1>
        <p class="subtitle">Accessories</p>

        <!-- 选项卡区域 -->
        <div class="tabs-container">
          <el-tabs class="module-tabs" v-model="activeTab">
            <el-tab-pane class="tab-pane" label="电源适配器" name="power"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="数据线缆" name="data"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="采集卡" name="card"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="工具" name="tools"></el-tab-pane>
          </el-tabs>
        </div>

        <!-- 内容区域 -->
        <div class="content-section">
          <div v-if="activeTab === 'data'" class="parts-container">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in dataParts" :key="index">
                <el-card class="part-card" shadow="hover">
                  <div class="part-image-container">
                    <img :src="item.image" class="part-image" :alt="item.name" />
                  </div>
                  <div class="part-content">
                    <h3 class="part-name">{{ item.name }}</h3>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>

          <div v-if="activeTab === 'card'" class="parts-container">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in cardParts" :key="index">
                <el-card class="part-card" shadow="hover">
                  <div class="part-image-container">
                    <img :src="item.image" class="part-image" :alt="item.name" />
                  </div>
                  <div class="part-content">
                    <h3 class="part-name">{{ item.name }}</h3>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>

          <div v-if="activeTab === 'power'" class="parts-container">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in powerParts" :key="index">
                <el-card class="part-card" shadow="hover">
                  <div class="part-image-container">
                    <img :src="item.image" class="part-image" :alt="item.name" />
                  </div>
                  <div class="part-content">
                    <h3 class="part-name">{{ item.name }}</h3>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>

          <div v-if="activeTab === 'tools'" class="parts-container">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in toolsParts" :key="index">
                <el-card class="part-card" shadow="hover">
                  <div class="part-image-container">
                    <img :src="item.image" class="part-image" :alt="item.name" />
                  </div>
                  <div class="part-content">
                    <h3 class="part-name">{{ item.name }}</h3>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'data',
      dataParts: [
        { name: 'ADS-26FSG-12 12024EPCN', image: require('@/media/cable01.png') },
        { name: 'ADS-13FSG-12 12024EPCN', image: require('@/media/cable01.png') },
      ],
      cardParts: [
        { name: 'BOQ-XGCL02A', image: require('@/media/card01.png') },
        { name: 'BOQ-HZ07C', image: require('@/media/card02.png') },
      ],
      powerParts: [
        { name: 'Adapter12V3A', image: require('@/media/io01.png') },
        { name: 'Adapter24V2A', image: require('@/media/io02.png') },
      ],
      toolsParts: [
        { name: 'BOQ-VIEWER1', image: require('@/media/tool01.png') },
        { name: 'BOQ-VIEWER2', image: require('@/media/tool02.png') },
        { name: 'BOQ-CameraAdapter1', image: require('@/media/tool03.png') },
        { name: 'BOQ-CameraAdapter2', image: require('@/media/tool04.png') },
        { name: 'BOQ-CameraAdapter3', image: require('@/media/tool05.png') },
      ],
    }
  },
  methods: {
    goToProduct() {
      this.$router.push('/Product');
    }
  }
}
</script>

<style scoped>
.more-product-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

/* 顶部图片样式 */
.promo-image-container {
  margin-bottom: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.promo-image {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  display: block;
}

/* 标题样式 */
.page-title {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  padding-left: 0px;
  font-size: 2rem;
  color: #2c3e50;
}

.page-title::after {
  content: '';
  display: block;
  width: var(--after-width);
  height: 6px;
  background-color: #409eff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) skewX(-20deg);
  bottom: -1px;
}

.subtitle {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 40px;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* 选项卡样式 */
.tabs-container {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  overflow: hidden;
}

.module-tabs {
  width: 100%;
}

:deep(.el-tabs__header) {
  margin: 0 auto;
  width: fit-content;
}

:deep(.el-tabs__nav-wrap) {
  margin-bottom: 0;
}

:deep(.el-tabs__nav) {
  float: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

:deep(.el-tabs__item) {
  flex: 0 0 auto;
  text-align: center;
  font-size: 18px;
  padding: 0 40px;
  white-space: nowrap;
  letter-spacing: 2px;
}

:deep(.el-tabs__content) {
  overflow: visible;
}

/* 内容区域样式 */
.content-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.parts-container {
  margin: 0;
}

.part-card {
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: none;
}

.part-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.part-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
}

.part-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.part-content {
  padding: 15px 0;
}

.part-name {
  font-size: 16px;
  color: #2c3e50;
  margin: 0;
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
}

/* 响应式设计 */
@media (max-width: 1400px) {
  .more-product-container {
    padding: 15px;
  }
  
  :deep(.el-tabs__item) {
    padding: 0 35px;
    font-size: 17px;
    letter-spacing: 1.5px;
  }
  
  :deep(.el-tabs__nav) {
    gap: 25px;
  }
}

@media (max-width: 992px) {
  .content-section {
    padding: 20px;
  }
  
  .part-image-container {
    height: 180px;
  }
  
  :deep(.el-tabs__item) {
    padding: 0 30px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  :deep(.el-tabs__nav) {
    gap: 20px;
  }
  
  .tabs-container {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .more-product-container {
    padding: 10px;
  }
  
  .page-title {
    font-size: 1.5rem;
  }
  
  .content-section {
    padding: 15px;
  }
  
  .part-image-container {
    height: 160px;
  }
  
  :deep(.el-tabs__item) {
    padding: 0 25px;
    font-size: 15px;
  }
  
  :deep(.el-tabs__nav) {
    width: 100%;
    justify-content: space-around;
    gap: 15px;
  }
  
  .tabs-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 1.3rem;
  }
  
  .part-image-container {
    height: 140px;
  }
  
  .part-name {
    font-size: 14px;
  }
  
  :deep(.el-tabs__item) {
    padding: 0 15px;
    font-size: 14px;
  }
  
  :deep(.el-tabs__nav) {
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tabs-container {
    padding: 8px;
  }
}
</style>
